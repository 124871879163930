// src/modules/dashboard/components/SpecialtyAssignmentBarChart/SpecialtyAssignmentBarChart.jsx

import React, { useState, useEffect } from "react"
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  ResponsiveContainer,
  Cell,
} from "recharts"
import { Dropdown, Form } from "react-bootstrap"
import { CircularProgress, Typography, Card } from "@mui/material"
import statsService from "../../services/statsService"
import { FaFilter } from "react-icons/fa"
const SpecialtyAssignmentBarChart = () => {
  const [loading, setLoading] = useState(false)
  const [chartData, setChartData] = useState([])
  const [specialties, setSpecialties] = useState([])
  const [selectedSpecialties, setSelectedSpecialties] = useState([])
  const [hoveredBar, setHoveredBar] = useState(null)
  const [dateRange, setDateRange] = useState(30)
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true)
      try {
        const endDate = new Date()
        const startDate = new Date()
        startDate.setDate(endDate.getDate() - dateRange)

        const formatDate = (date) => date.toISOString().split("T")[0]
        const formattedStartDate =
          dateRange !== 0 ? formatDate(startDate) : null
        const formattedEndDate = dateRange !== 0 ? formatDate(endDate) : null

        const response = await statsService.getSpecialtiesAssignmentStates(
          formattedStartDate,
          formattedEndDate
        )
        const { data } = response

        if (!data || Object.keys(data).length === 0) {
          console.warn("No data returned from API.")
          setChartData([])
          setSpecialties([])
          setSelectedSpecialties([])
          return
        }

        const specialtiesSet = new Set()
        const processedData = []

        Object.values(data).forEach((stateObj) => {
          const stateName = stateObj.name || "Estado desconocido"
          const stateData = { state: stateName }

          if (stateObj.specialties) {
            const specialtiesArray = Array.isArray(stateObj.specialties)
              ? stateObj.specialties
              : Object.values(stateObj.specialties)

            specialtiesArray.forEach((specialty) => {
              const specialtyName = specialty.name || "Desconocida"
              const percentage = parseFloat(specialty.percentage) || 0

              stateData[specialtyName] = percentage
              specialtiesSet.add(specialtyName)
            })
          } else {
            // If specialties are not present, treat the stateObj itself as a specialty
            const specialtyName = stateObj.name || "Desconocida"
            const percentage = parseFloat(stateObj.percentage) || 0

            stateData[specialtyName] = percentage
            specialtiesSet.add(specialtyName)
          }

          processedData.push(stateData)
        })

        const specialtiesList = Array.from(specialtiesSet)

        setChartData(processedData)
        setSpecialties(specialtiesList)
        setSelectedSpecialties(specialtiesList)
      } catch (error) {
        console.error("Error fetching data:", error)
      } finally {
        setLoading(false)
      }
    }

    fetchData()
  }, [dateRange])

  const handleDateRangeSelect = (eventKey) => {
    setDateRange(parseInt(eventKey, 10))
  }

  // Handle specialty filter toggle
  const handleSpecialtyToggle = (specialty) => {
    setSelectedSpecialties((prevSelected) =>
      prevSelected.includes(specialty)
        ? prevSelected.filter((s) => s !== specialty)
        : [...prevSelected, specialty]
    )
  }

  // Reset hoveredBar when selectedSpecialties change
  useEffect(() => {
    if (hoveredBar && !selectedSpecialties.includes(hoveredBar?.specialty)) {
      setHoveredBar(null)
    }
  }, [selectedSpecialties, hoveredBar])

  // Define colors for the bars
  const colors = [
    "#8884d8",
    "#82ca9d",
    "#ff7300",
    "#0088fe",
    "#ffbb28",
    "#ff8042",
    "#a4de6c",
    "#8dd1e1",
    "#d88884",
    "#FFC0CB",
    "#FF69B4",
  ]

  // Custom Tooltip Component
  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <Card
          style={{
            padding: "15px 20px",
            borderRadius: "10px",
            backgroundColor: "rgba(255, 255, 255, 0.75)", // Semi-transparent white
            boxShadow: "0 4px 20px rgba(0, 0, 0, 0.1)",
            pointerEvents: "none", // Prevents tooltip from capturing mouse events
          }}
        >
          {hoveredBar ? (
            <>
              <Typography
                variant="h6"
                style={{
                  fontWeight: "bold",
                  color: "#000",
                  marginBottom: "5px",
                }}
              >
                {hoveredBar.specialty}
              </Typography>
              <Typography>
                {
                  chartData?.find((data) => data?.state === hoveredBar?.state)[
                    hoveredBar.specialty
                  ]
                }
                % en {hoveredBar.state}
              </Typography>
            </>
          ) : (
            // blank div with space to prevent tooltip from disappearing
            <div
              style={{
                height: "60px",
                width: "200px",
              }}
            ></div>
          )}
        </Card>
      )
    }

    return null
  }

  return (
    <div style={{ padding: " 20px 0" }}>
      <div className="d-flex justify-content-between align-items-center mb-3 mt-1">
        <h5 className="mb-0 text-lg ml-2">Convertibilidad por categoría general</h5>
        <Dropdown onSelect={handleDateRangeSelect}>
          <Dropdown.Toggle variant="primary" id="dropdown-date-range">
            {dateRange !== 0 ? `Últimos ${dateRange} días` : "Histórico"}
          </Dropdown.Toggle>

          <Dropdown.Menu>
            <Dropdown.Item eventKey="7">7 días</Dropdown.Item>
            <Dropdown.Item eventKey="15">15 días</Dropdown.Item>
            <Dropdown.Item eventKey="30">30 días</Dropdown.Item>
            <Dropdown.Item eventKey="0">Histórico</Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </div>
      <div className="d-flex justify-end align-items-center mb-3">
        <Dropdown>
          <Dropdown.Toggle variant="primary" id="dropdown-column-visibility">
            <FaFilter className="me-2" />
            Especialidades
          </Dropdown.Toggle>

          <Dropdown.Menu>
            {specialties.map((specialty) => (
              <Dropdown.Item
                key={specialty}
                onClick={() => handleSpecialtyToggle(specialty)}
              >
                <Form.Check
                  type="checkbox"
                  label={specialty}
                  checked={selectedSpecialties.includes(specialty)}
                  readOnly
                />
              </Dropdown.Item>
            ))}
          </Dropdown.Menu>
        </Dropdown>
      </div>

      {/* Loading Indicator */}
      {loading ? (
        <div style={{ textAlign: "center", padding: "50px" }}>
          <CircularProgress />
        </div>
      ) : chartData?.length > 0 ? (
        <ResponsiveContainer width="100%" height={500}>
          <BarChart
            data={chartData}
            margin={{ top: 20, right: 30, left: 20, bottom: 20 }}
          >
            <XAxis
              dataKey="state"
              label={{
                value: "Estados",
                position: "insideBottom",
                offset: -20,
              }}
            />
            <YAxis
              label={{
                value: "Porcentaje (%)",
                angle: -90,
                position: "insideLeft",
              }}
            />
            <Tooltip content={<CustomTooltip />} />
            <Legend
              verticalAlign="top"
              iconType="circle"
              align="center"
              wrapperStyle={{ paddingBottom: "20px" }}
            />
            {selectedSpecialties.map((specialty, index) => (
              <Bar
                key={specialty}
                dataKey={specialty}
                fill={colors[index % colors.length]}
                barSize={20}
                background={{ fill: "rgba(0, 0, 0, 0.01)" }} // Light gray background
                style={{ cursor: "pointer" }}
              >
                {chartData?.map((entry, idx) => {
                  const isHovered =
                    hoveredBar &&
                    hoveredBar.state === entry.state &&
                    hoveredBar.specialty === specialty

                  return (
                    <Cell
                      key={`cell-${idx}`}
                      fill={
                        isHovered
                          ? "rgba(0, 0, 0, 0.8)" // Highlight color on hover
                          : colors[index % colors.length]
                      }
                      opacity={isHovered ? 1 : 0.8}
                      onMouseEnter={() =>
                        setHoveredBar({ state: entry.state, specialty })
                      }
                      onMouseLeave={() => setHoveredBar(null)}
                      style={{ transition: "all 0.15s ease" }}
                    />
                  )
                })}
              </Bar>
            ))}
          </BarChart>
        </ResponsiveContainer>
      ) : (
        <Typography variant="body1">No hay datos disponibles</Typography>
      )}
    </div>
  )
}

export default SpecialtyAssignmentBarChart
