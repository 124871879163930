import React, { useState, useEffect, useMemo } from "react"
import {
  Container,
  Row,
  Col,
  Card,
  Table,
  Dropdown,
  Form,
  Button,
  Spinner,
  Alert,
} from "react-bootstrap"
import {
  FaSort,
  FaSortUp,
  FaSortDown,
  FaFilter,
  FaSearch,
} from "react-icons/fa" // Using react-icons for icons
import "./LawyerAssignmentTable.css" // Import custom CSS for additional styling
import debounce from "lodash.debounce" // Import debounce for search input
import statsService from "../../services/statsService"
import { Link } from "react-router-dom"

const LawyerAssignmentTable = () => {
  // State variables
  const [data, setData] = useState([]) // Fetched data
  const [states, setStates] = useState([]) // Unique state names
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)

  const [dateRange, setDateRange] = useState(7) // Default to 7 days
  const [pageNumber, setPageNumber] = useState(1)
  const [pageSize, setPageSize] = useState(50)
  const [totalPages, setTotalPages] = useState(1)
  const [totalRecords, setTotalRecords] = useState(0)

  const [searchQuery, setSearchQuery] = useState("") // Search query state

  const [visibleStates, setVisibleStates] = useState([]) // State to manage visible columns

  const [sortConfig, setSortConfig] = useState({
    key: "total_assignments",
    sort_state_id: null,
    direction: "desc",
  })

  // Debounced search to prevent excessive API calls
  const debouncedSearch = useMemo(
    () =>
      debounce((query) => {
        setSearchQuery(query)
        setPageNumber(1) // Reset to first page on search
      }, 500),
    []
  )

  const handleSearchChange = (event) => {
    debouncedSearch(event.target.value)
  }

  // Initialize visibleStates with all states when states change
  useEffect(() => {
    setVisibleStates(states)
  }, [states])

  // Cleanup debounce on unmount
  useEffect(() => {
    return () => {
      debouncedSearch.cancel()
    }
  }, [debouncedSearch])

  // Fetch data whenever dateRange, pageNumber, pageSize, searchQuery, sortConfig change
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true)
      setError(null)

      // Calculate startDate and endDate based on dateRange
      const endDate = new Date()
      const startDate = new Date()
      startDate.setDate(endDate.getDate() - dateRange)

      // Format dates as YYYY-MM-DD
      const formatDate = (date) => date.toISOString().split("T")[0]
      const formattedStartDate = formatDate(startDate)
      const formattedEndDate = formatDate(endDate)

      try {
        const response = await statsService.getLawyerAssignmentStates(
          formattedStartDate,
          formattedEndDate,
          pageNumber,
          pageSize,
          searchQuery,
          sortConfig.key,
          sortConfig.direction,
          sortConfig.sort_state_id
        )

        const lawyerData = response.data
        const paginationData = response.pagination
        let allStates = response.states.map((state) => state.state)

        setData(lawyerData)
        setStates(allStates)
        setTotalPages(paginationData.total_pages)
        setTotalRecords(paginationData.total_records)
      } catch (err) {
        if (err.response) {
          // Server responded with a status other than 2xx
          setError(
            `Error ${err.response.status}: ${
              err.response.data.error || "Something went wrong."
            }`
          )
        } else if (err.request) {
          // Request was made but no response received
          setError(
            "No response from the server. Please check your network connection."
          )
        } else {
          // Something else happened
          setError("An unexpected error occurred.")
        }
        console.error(err)
      } finally {
        setLoading(false)
      }
    }

    fetchData()
  }, [dateRange, pageNumber, pageSize, searchQuery, sortConfig])

  // Handle date range change
  const handleDateRangeSelect = (eventKey) => {
    setDateRange(parseInt(eventKey, 10))
    setPageNumber(1) // Reset to first page
  }

  // Handle page change
  const handlePageChange = (newPage) => {
    setPageNumber(newPage)
  }

  // // Handle page size change
  // const handlePageSizeChange = (event) => {
  //   setPageSize(parseInt(event.target.value, 10))
  //   setPageNumber(1) // Reset to first page
  // }

  // Handle column visibility toggle
  const handleColumnToggle = (stateName) => {
    if (visibleStates.includes(stateName)) {
      setVisibleStates(visibleStates.filter((s) => s !== stateName))
    } else {
      setVisibleStates([...visibleStates, stateName])
    }
    setPageNumber(1) // Reset to first page on column toggle
  }

  const handleSort = (key, sort_state_id = null) => {
    const direction =
      sortConfig.key === key && sortConfig.direction === "asc" ? "desc" : "asc"
    if (sort_state_id) {
      setSortConfig({ key, sort_state_id, direction })
    } else {
      setSortConfig({ key, direction })
    }
  }

  const renderSortIcon = (key, sort_state_id = null) => {
    if (sortConfig.key === key) {
      if (sort_state_id && sortConfig.sort_state_id === sort_state_id) {
        return sortConfig.direction === "asc" ? <FaSortUp /> : <FaSortDown />
      } else if (sort_state_id && sortConfig.sort_state_id !== sort_state_id) {
        return <FaSort />
      }
      return sortConfig.direction === "asc" ? <FaSortUp /> : <FaSortDown />
    }
    return <FaSort />
  }

  return (
    <Container fluid className="mt-4">
      <Row>
        <Col xs={12}>
          <Card>
            <Card.Body>
              {/* Header and Controls */}
              <div className="d-flex justify-content-between align-items-center mb-3 mt-1">
                <h5 className="mb-0 text-lg ml-2">
                  Listado de abogados activos
                </h5>
                <Dropdown onSelect={handleDateRangeSelect}>
                  <Dropdown.Toggle variant="primary" id="dropdown-date-range">
                    Últimos {dateRange} días
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Dropdown.Item eventKey="7">7 días</Dropdown.Item>
                    <Dropdown.Item eventKey="15">15 días</Dropdown.Item>
                    <Dropdown.Item eventKey="30">30 días</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>

              {/* Search and Column Toggle */}
              <div className="d-flex justify-content-between align-items-center mb-3">
                {/* Search Input */}
                <Form.Group
                  controlId="search"
                  className="d-flex align-items-center"
                >
                  <FaSearch className="me-2" />
                  <Form.Control
                    type="text"
                    placeholder="Buscar por abogado..."
                    onChange={handleSearchChange}
                  />
                </Form.Group>

                {/* Column Visibility Dropdown */}
                <Dropdown>
                  <Dropdown.Toggle
                    variant="primary"
                    id="dropdown-column-visibility"
                  >
                    <FaFilter className="me-2" />
                    Columnas
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    {states.map((stateName) => (
                      <Dropdown.Item
                        key={stateName}
                        onClick={() => handleColumnToggle(stateName)}
                      >
                        <Form.Check
                          type="checkbox"
                          label={stateName}
                          checked={visibleStates.includes(stateName)}
                          readOnly
                        />
                      </Dropdown.Item>
                    ))}
                  </Dropdown.Menu>
                </Dropdown>
              </div>

              {/* Table */}
              {loading ? (
                <div className="d-flex justify-content-center align-items-center">
                  <div style={{ minHeight: "511px" }}>
                    <Spinner animation="border" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </Spinner>
                  </div>
                </div>
              ) : error ? (
                <Alert variant="danger">{error}</Alert>
              ) : (
                <div className="mt-4">
                  <div className="table-responsive LawyerAssignmentTable-scroll mb-3">
                    <Table striped bordered hover>
                      <thead>
                        <tr>
                          <th
                            style={{ cursor: "pointer" }}
                            onClick={() => handleSort("lawyer_business_name")}
                          >
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                            >
                              <span>Abogados activos</span>
                              <span>
                                {renderSortIcon("lawyer_business_name")}
                              </span>
                            </div>
                          </th>
                          <th
                            style={{ cursor: "pointer" }}
                            onClick={() => handleSort("total_assignments")}
                          >
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                            >
                              <span>Leads Derivados</span>
                              <span>{renderSortIcon("total_assignments")}</span>
                            </div>
                          </th>
                          <th
                            style={{ cursor: "pointer" }}
                            onClick={() => handleSort("times_budgeted")}
                          >
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                            >
                              <span>Leads Presupestados</span>
                              <span>{renderSortIcon("times_budgeted")}</span>
                            </div>
                          </th>
                          {data[0]?.states.map(
                            (state) =>
                              visibleStates.includes(state.state_name) && (
                                <th
                                  key={state.state_id}
                                  style={{ cursor: "pointer" }}
                                  onClick={() =>
                                    handleSort("state", state.state_id)
                                  }
                                >
                                  <div
                                    style={{
                                      display: "flex",
                                      flexDirection: "column",
                                      alignItems: "center",
                                      justifyContent: "center",
                                    }}
                                  >
                                    <span>{state.state_name}</span>
                                    <span>
                                      {renderSortIcon("state", state.state_id)}
                                    </span>
                                  </div>
                                </th>
                              )
                          )}
                        </tr>
                      </thead>
                      <tbody>
                        {data.length === 0 ? (
                          <tr>
                            <td
                              colSpan={2 + visibleStates.length}
                              className="text-center"
                            >
                              No data available.
                            </td>
                          </tr>
                        ) : (
                          data.map((lawyer) => (
                            <tr
                              key={
                                lawyer.lawyer_id || lawyer.lawyer_business_name
                              }
                            >
                              <td>
                                <Link to={`/lawyer/${lawyer?.lawyer_id}`}>
                                  {lawyer.lawyer_business_name}
                                </Link>
                              </td>
                              <td>{lawyer.total_assignments}</td>
                              <td>{lawyer?.times_budgeted}</td>
                              {visibleStates.map((stateName) => {
                                const state = lawyer.states.find(
                                  (s) => s.state_name === stateName
                                )
                                const count = state ? state.lead_count : 0
                                return <td key={stateName}>{count || "-"}</td>
                              })}
                            </tr>
                          ))
                        )}
                      </tbody>
                    </Table>
                  </div>

                  {/* Pagination Controls */}
                  <div className="d-flex justify-content-between align-ite  ms-center">
                    <div>
                      Página {pageNumber} de {totalPages} | Resultados totales:{" "}
                      {totalRecords}
                    </div>
                    <div>
                      <Button
                        variant="secondary"
                        size="sm"
                        className="me-2 mt-1"
                        onClick={() => handlePageChange(1)}
                        disabled={pageNumber === 1}
                      >
                        Primera página
                      </Button>
                      <Button
                        variant="secondary"
                        size="sm"
                        className="me-2 mt-1"
                        onClick={() => handlePageChange(pageNumber - 1)}
                        disabled={pageNumber === 1}
                      >
                        Anterior
                      </Button>
                      <Button
                        variant="secondary"
                        size="sm"
                        className="me-2 mt-1"
                        onClick={() => handlePageChange(pageNumber + 1)}
                        disabled={pageNumber === totalPages}
                      >
                        Siguiente
                      </Button>
                      <Button
                        variant="secondary"
                        size="sm"
                        onClick={() => handlePageChange(totalPages)}
                        disabled={pageNumber === totalPages}
                        className="me-2 mt-1"
                      >
                        Última página
                      </Button>
                    </div>
                  </div>
                </div>
              )}
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  )
}

export default LawyerAssignmentTable
