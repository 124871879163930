import React, { useEffect, useState } from "react"
import { PieChart, Pie, Cell, Tooltip, ResponsiveContainer } from "recharts"
import { Dropdown, Spinner, Alert, Form, Button } from "react-bootstrap"
import statsService from "../../services/statsService" // Adjust the path as needed
import { FaFilter } from "react-icons/fa"
import { Card, Typography } from "@mui/material"
const LeadStatePieChart = () => {
  const [loading, setLoading] = useState(false)
  const [chartData, setChartData] = useState([])
  const [dateRange, setDateRange] = useState(30) // Default to last 7 days
  const [error, setError] = useState(null)
  const [totalLeadCount, setTotalLeadCount] = useState(0)
  const [specialties, setSpecialties] = useState([{}])
  const [selectedSpecialties, setSelectedSpecialties] = useState([])
  const [isInitialized, setIsInitialized] = useState(false) // Initialization flag
  const [tempSelectedSpecialties, setTempSelectedSpecialties] = useState([]) // Temporary selected specialties

  const COLORS = [
    "#8884d8",
    "#82ca9d",
    "#ff7300",
    "#0088fe",
    "#ffbb28",
    "#ff8042",
    "#a4de6c",
    "#8dd1e1",
    "#d88884",
    "#FFC0CB",
    "#FF69B4",
  ]

  const handleDateRangeSelect = (eventKey) => {
    const selectedRange = parseInt(eventKey, 10)
    setDateRange(selectedRange)
  }

  const formatDate = (date) => date.toISOString().split("T")[0]

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true)
      setError(null) // Reset error state
      try {
        const endDate = new Date()
        const startDate = new Date()
        startDate.setDate(endDate.getDate() - dateRange)

        const formattedStartDate = formatDate(startDate)
        const formattedEndDate = formatDate(endDate)

        const response = await statsService.getDerivedLeadStates(
          formattedStartDate,
          formattedEndDate,
          selectedSpecialties
        )
        const { data } = response

        if (!data || Object.keys(data).length === 0) {
          console.warn("No data returned from API.")
          setChartData([])
          return
        }

        const processedData = Object.values(data.states).map((stateObj) => {
          const stateName = stateObj.state || "Estado desconocido"
          const stateData = {
            name: stateName,
            value: stateObj.percentage,
            count: stateObj.lead_count || 0, // Ensure count is defined
          }
          return stateData
        })
        setTotalLeadCount(data.total_lead_count)
        setChartData(processedData)
        if (!isInitialized) {
          setSpecialties(data.specialties)
          const allSpecialtyIds = data.specialties.map(
            (specialty) => specialty.id
          )
          setSelectedSpecialties(allSpecialtyIds)
          setTempSelectedSpecialties(allSpecialtyIds)
          setIsInitialized(true)
        }
      } catch (error) {
        console.error("Error fetching data:", error)
        setError(
          "Hubo un error al cargar los datos. Por favor, inténtalo de nuevo."
        )
      } finally {
        setLoading(false)
      }
    }

    fetchData()
  }, [dateRange, selectedSpecialties, isInitialized])

  const CustomTooltip = ({ active, payload }) => {
    if (active && payload && payload.length) {
      const { name, value, count } = payload[0].payload
      return (
        <Card
          style={{
            padding: "15px 20px",
            borderRadius: "10px",
            backgroundColor: "rgba(255, 255, 255, 0.75)", // Semi-transparent white
            boxShadow: "0 4px 20px rgba(0, 0, 0, 0.1)",
            pointerEvents: "none", // Prevents tooltip from capturing mouse events
          }}
        >
            <Typography
                variant="h6"
                style={{
                  fontWeight: "bold",
                  color: "#000",
                  marginBottom: "5px",
                }}
              >
                {name} {value}%
              </Typography>
              <Typography>
              {count} de {totalLeadCount} leads
              </Typography>
          
        </Card>
      )
    }

    return null
  }

  const handleSpecialtyToggle = (id) => {
    if (tempSelectedSpecialties.includes(id)) {
      setTempSelectedSpecialties(
        tempSelectedSpecialties.filter((specialtyId) => specialtyId !== id)
      )
    } else {
      setTempSelectedSpecialties([...tempSelectedSpecialties, id])
    }
  }

  const applySpecialtyFilters = () => {
    setSelectedSpecialties(tempSelectedSpecialties)
  }

  return (
    <div style={{ padding: " 20px 0" }}>
      <div className="d-flex justify-content-between align-items-center mb-3 ">
        <h5 className="mb-0 text-lg ml-2">Estado de leads derivados</h5>
        {/* Date Range Dropdown */}
        <div className="d-flex gap-2">
          <Dropdown onSelect={handleDateRangeSelect}>
            <Dropdown.Toggle variant="primary" id="dropdown-date-range">
              Últimos {dateRange} días
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Item eventKey="7">7 días</Dropdown.Item>
              <Dropdown.Item eventKey="15">15 días</Dropdown.Item>
              <Dropdown.Item eventKey="30">30 días</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
          <Dropdown>
            <Dropdown.Toggle variant="primary" id="dropdown-column-visibility">
              <FaFilter className="me-2" />
              Especialidades
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <div className="px-3 pt-1">
                {specialties.length === 0 ? (
                  <div>No hay especialidades disponibles.</div>
                ) : (
                  specialties.map((specialty) => (
                    <Form.Check
                      key={specialty.id}
                      type="checkbox"
                      id={`specialty-${specialty.id}`}
                      label={specialty.name}
                      checked={tempSelectedSpecialties.includes(specialty.id)}
                      onChange={() => handleSpecialtyToggle(specialty.id)}
                    />
                  ))
                )}
              </div>
              <div className="d-flex justify-content-center p-2">
                <Button
                  variant="secondary"
                  size="sm"
                  onClick={applySpecialtyFilters}
                  disabled={loading}
                  className="me-2"
                >
                  Aplicar filtros
                </Button>
              </div>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </div>
      {/* Specialties Filter Dropdown */}
      <div className="d-flex justify-content-end align-items-center mb-1"></div>

      {loading ? (
        <div
          className="d-flex justify-content-center align-items-center"
          style={{ height: "400px" }}
        >
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Cargando...</span>
          </Spinner>
        </div>
      ) : error ? (
        <Alert variant="danger">{error}</Alert>
      ) : chartData?.length === 0 ? (
        <Alert variant="info">
          No hay datos disponibles para el rango de fechas seleccionado.
        </Alert>
      ) : (
        <ResponsiveContainer width="100%" minHeight={550}>
          <PieChart>
            <Pie
              data={chartData}
              dataKey="value"
              nameKey="name"
              cx="50%"
              cy="50%"
              innerRadius={110}
              outerRadius={220}
              labelLine={false}
              label={({ name, value }) =>
                value > 0.5 ? `${name} (${value}%)` : null
              }
            >
              {chartData?.map((entry, index) => (
                <Cell
                  key={`cell-${index}`}
                  fill={COLORS[index % COLORS.length]}
                />
              ))}
            </Pie>
            <Tooltip content={<CustomTooltip />} />
          </PieChart>
        </ResponsiveContainer>
      )}
    </div>
  )
}

export default LeadStatePieChart
