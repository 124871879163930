// statsService.js

import axios from "axios"

class StatsService {
  api

  constructor() {
    this.api = axios.create({
      baseURL: `${process.env.REACT_APP_API_URL}/stats`,
    })
    this.api.interceptors.request.use((config) => {
      const token = localStorage.getItem("Token")

      if (token) {
        config.headers = {
          Authorization: `Token ${token}`,
          withCredentials: false,
          "Content-Type": "application/json; charset=UTF-8",
        }
      }

      return config
    })
  }

  /**
   * Fetches lawyer assignment states with server-side filtering and sorting.
   * @param {string} startDate - The start date in YYYY-MM-DD format.
   * @param {string} endDate - The end date in YYYY-MM-DD format.
   * @param {number} pageNumber - The current page number.
   * @param {number} pageSize - The number of records per page.
   * @param {string} searchQuery - The search query for filtering by lawyer name.
   * @param {string} sortBy - The field to sort by.
   * @param {string} order - The sort order ('asc' or 'desc').
   * @param {number} sort_state_id - The state id to filter by.
   * @returns {Promise<Object>} - The API response data.
   */
  getLawyerAssignmentStates = async (
    startDate,
    endDate,
    pageNumber,
    pageSize,
    searchQuery = "",
    sortBy = "lawyer_name",
    order = "asc",
    sort_state_id = null
  ) => {
    try {
      const response = await this.api.get(`/lawyer-assignment-states/`, {
        params: {
          start_date: startDate,
          end_date: endDate,
          page: pageNumber,
          page_size: pageSize,
          search: searchQuery,
          sort_by: sortBy,
          order: order,
          sort_state_id: sort_state_id,
        },
      })
      return response.data
    } catch (error) {
      throw error
    }
  }

  /**
   * Fetches lawyer assignment states with server-side filtering and sorting.
   * @param {string} startDate - The start date in YYYY-MM-DD format.
   * @param {string} endDate - The end date in YYYY-MM-DD format.
   * @returns {Promise<Object>} - The API response data.
   */
  getSpecialtiesAssignmentStates = async (startDate, endDate) => {
    try {
      const response = await this.api.get(`/assignment-states-specialties/`, {
        params: {
          start_date: startDate,
          end_date: endDate,
        },
      })
      return response
    } catch (error) {
      throw error
    }
  }

  /**
   * Fetches lead states with server-side filtering and sorting.
*    @param {string} startDate - The start date in YYYY-MM-DD format.
   * @param {string} endDate - The end date in YYYY-MM-DD format
   * @param {Array}.specialties - The specialties to filter by.
   * @returns {Promise<Object>} - The API response data.
   */
  getDerivedLeadStates = async (startDate, endDate, specialties) => {
    try {
      const response = await this.api.get(`/derived-leads-states/`, {
        params: {
          start_date: startDate,
          end_date: endDate,
          specialties: specialties,
        },
      })

      return response
    } catch (error) {
      throw error
    }
  }
}

const statsService = new StatsService()

export default statsService
